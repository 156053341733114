import React from 'react';
import { graphql } from 'gatsby';

import { blogPagePropType } from '../proptypes/pages';
import { Layout, HeadSection } from '../components/layout';
import Blogs from '../components/Blogs';
import Blog from '../components/Blog';
import Seo from '../components/Seo';

function BlogPage({ data }) {
  const {
    allStrapiBlog: { nodes: blogs },
  } = data;

  const featuredBlog = blogs[0];

  return (
    <Layout>
      <HeadSection>
        <Blog data={featuredBlog} variant="twoCol" />
      </HeadSection>
      <Blogs blogs={blogs.slice(1)} />
    </Layout>
  );
}

export const query = graphql`
  {
    allStrapiBlog(sort: { fields: [Date, Featured], order: [DESC, DESC] }, filter: { Website: { ne: false } }) {
      nodes {
        Slug
        Text {
          data {
            Text
          }
        }
        Date
        Teaser
        id
        Title
        Subtitle
        Cover {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
    metaData(yamlId: { eq: "blog" }) {
      description
      sitetitle
    }
  }
`;

export default BlogPage;

// eslint-disable-next-line react/prop-types
export function Head({ data }) {
  // eslint-disable-next-line react/prop-types
  return <Seo title={data.metaData.sitetitle} description={data.metaData.description} />;
}

BlogPage.propTypes = blogPagePropType;
