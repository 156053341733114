import { PropTypes } from 'prop-types';
import { metaDataPropType } from './misc';
import { blogPropType } from './strapiPropTypes';

export const blogPagePropType = {
  data: PropTypes.shape({
    allStrapiBlog: PropTypes.shape({
      nodes: PropTypes.arrayOf(blogPropType),
    }),
    metaData: metaDataPropType,
  }),
};

export const notFoundPagePropType = {};

/*
export const blogImagePropType = PropTypes.exact({
  localFile: PropTypes.exact({
    childImageSharp: PropTypes.exact({
      gatsbyImageData: PropTypes.exact({
        layout: PropTypes.string,
        placeholder: PropTypes.exact({
          fallback: PropTypes.string,
        }),
        backgroundColor: PropTypes.string,
        images: PropTypes.exact({
          fallback: PropTypes.exact({
            src: PropTypes.string,
            srcSet: PropTypes.string,
            sizes: PropTypes.string,
          }),
          sources: PropTypes.arrayOf(
            PropTypes.exact({
              srcSet: PropTypes.string,
              type: PropTypes.string,
              sizes: PropTypes.string,
            })
          ),
        }),
        width: PropTypes.number,
        height: PropTypes.number,
      }),
    }),
  }),
});
*/
